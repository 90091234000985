import { Component } from '@angular/core';
import { EventService } from '../service/event.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent  {
  ngOnInit() { 
    this.currentDate = new Date();
  }

  currentDate: Date;
  build: string = "";
  constructor(protected eventService: EventService) {
    this.eventService.release.subscribe(r => {
      this.build = r;
    });
  }
}
