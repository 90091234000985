import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { CartSesrvice, PlayerInfo, PaymentInfo, StripeAuth } from '../service/cart.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService, BingoPackage, FutureEvent, TAEvent } from '../service/event.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { evtvendor, sponsor } from '../service/sponsor.service';



@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css'],
})

export class CartComponent implements OnInit {

  @Input()
  fullCart: boolean = false;

  showCheckout: boolean = false;
  isSubmitting = false;
  showThankyou = false;
  errorMessage = '';  
  organDonorDiscount = false;
  couponMessage = '';

  futureEvents: Array<FutureEvent> = [];
  organDonors: Array<string> = [];
  bingoPackage: BingoPackage;
  paymentInfo: PaymentInfo;
  players: Array<PlayerInfo> = [];
  total: number = 0;

  backupInformation: Array<PlayerInfo> = [];

  unknownissue: string = '';

  selectedEvent: TAEvent;
  

  ngOnInit() { 
    
    this.route.pathFromRoot.forEach(r => {
      r.url.subscribe(x => {
        x.forEach(y => {
          if (y.path === 'cart' || y.path === 'checkout') {
            this.fullCart = true;
          }
        });
      });
    });


    this.paymentInfo = {
      name: '',
      email: '',
      number: undefined,
      exp_month: undefined,
      exp_year: undefined,
      cvc: undefined,
      address_zip: undefined,
      message: '',
      howdidyouhear: ''
    }
  }
  
  constructor(protected eventService: EventService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private cartService: CartSesrvice,
    private http: HttpClient,
    private router: Router) {
    
    this.cartService.players.subscribe(c => {
      console.log(c);
      this.players = c;
      this.total = 0;
      this.players.forEach(p => {
        if (p.info) {
          this.total = this.total + p.info.total;
          
        } else if (p.vendor) {
          this.total = this.total + p.vendor.paid;
        }
        
      });
      
    });

    this.eventService.organDonors.subscribe(o => {
      this.organDonors = o;
    });

    this.cartService.message.subscribe(m => this.errorMessage = m);

    this.eventService.futureEvents.subscribe(evt => this.futureEvents = evt);

  }
  


  removeItem(item: PlayerInfo) {
    const crt = this.players.filter(x => x.id !== item.id);
    
    this.cartService.players.next(crt);
    localStorage.setItem('cart', JSON.stringify(crt));
    location.href = '/cart';
  }


  submitHandler() {
    
    this.isSubmitting = true;
    let okToContinue = true;

    //Confirm all events have available seats
    this.players.forEach(async p => {
      if (p.info) {
        await this.eventService.getEventremianingPlayercountCount(p.info.eventId);
        this.eventService.availablePlayerCount.subscribe(c => {
          if (c < 1) {
            okToContinue = false;
            alert(`Seats no longer available for  ${p.info.eventName}`)
          }
        });
      }      
    });

    if (okToContinue) {
      
      const stripeCall: StripeAuth = {
        name: this.paymentInfo.name,
        number: this.paymentInfo.number,
        exp_month: this.paymentInfo.exp_month,
        exp_year: this.paymentInfo.exp_year,
        cvc: this.paymentInfo.cvc,
        address_zip: this.paymentInfo.address_zip,
      };

      (<any>window).Stripe.card.createToken(stripeCall, (status: number, response: any) => {
        console.log(status);
        if (status === 200) {

          let packages = [];
          let vendors: Array<evtvendor> = [];
          this.backupInformation = this.players;

          this.players.forEach(p => {
            if (p.info) {
              //packages.push(p.info);
              let player = {
                id: 0,
                eventid: p.info.eventId,
                cartid: 0,
                packagename: p.info.packageName,
                firstname: p.info.firstName,
                lastname: p.info.lastName,
                email: p.info.email,
                eventname: p.info.eventName,
                total: p.info.total,
                stripetoken: response.id,
                packagequantity: 1,
                extrabook: p.info.extraBook,
                quickdraw: p.info.quickdraw,
                tictac: p.info.ticTac,
                deal: p.info.deal,
                money: p.info.money,
                plinko: p.info.plinko,
                jackpot: p.info.jackpot,
                safe: p.info.safe,
                punch2Win: p.info.punch2Win,
                discount: p.info.discount,
                hardcard: p.info.hardcard,
                special: p.info.special,
                message: this.paymentInfo.message,
                play4: p.info.play4,
                papersize: Number(p.info.paperSize),
                butterfly: p.info.butterfly,
                leprechaun: p.info.leprechaun,
                minijackpot: p.info.miniJackpot,
                wackywheel: p.info.wackyWheel,
                //playercard: p.info.playercard,
                howdidyouhear: this.paymentInfo.howdidyouhear,
                winnerschoice: p.info.winnersChoice,
                computerSize: Number(p.info.computerSize),
                wizardofoz: Number(p.info.wizardofoz),
                speedball: Number(p.info.speedball),
                seatoption: p.info.seatoption
              };
              packages.push(player);
            }

            if (p.vendor) {
              vendors.push(p.vendor);
            }
            
          });

          let cart = {
            stripeToken: response.id,
            cartOwner: this.paymentInfo.name,
            cartEmail: this.paymentInfo.email,
            message: this.paymentInfo.message,
            howdidyouhear: this.paymentInfo.howdidyouhear,
            total: this.total,
            Packages: packages,
            vendors: vendors
          }
          console.log(cart);


          this.http.post(`/api/events/processpurchase`, cart).subscribe(x => {
            this.showThankyou = true;
            console.log(x);
            this.cd.detectChanges();
            this.cartService.clearCart();
            this.isSubmitting = false;
          },
            err => {
              this.isSubmitting = false;
              console.log(err.error);
              this.cartService.players.next(this.backupInformation);
              this.cartService.message.next(err.error + " - You must refresh this page to try your credit card or a different credit card again!!!");
              this.cd.detectChanges();
              this.showThankyou = false;

            });

        } else {
          console.log('Response:', response);
          console.log('Status', status);
          this.unknownissue = "There was a problem withour credit card processor. Please refresh this screen. Reenter and confirm all your information. "
          this.isSubmitting = false;
          this.cartService.players.next(this.backupInformation);
          this.showThankyou = false;
          this.cartService.message.next(response.error.message + " - You must refresh this page to try your credit card or a different credit card again!!!");
          this.cd.detectChanges();
        }
      });

    }

    

  }
}
