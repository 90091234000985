import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FullCalendarModule } from 'ng-fullcalendar';
import { Http, HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReCaptchaModule } from 'angular2-recaptcha';
import { JwSocialButtonsModule } from 'jw-angular-social-buttons';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './header/header.component';
import { NavigationComponent } from './navigation/navigation.component';
import { FooterComponent  } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { CalendarPageComponent } from './calendar/calendar.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { EventComponent } from './event/event.component';
import { EventsComponent } from './events/events.component';
import { EventService } from './service/event.service';

import * as $ from 'jquery';
import { CartSesrvice } from './service/cart.service';
import { CartComponent } from './cart/cart.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { JackpotComponent } from './Jackpots/jackpot.component';
// import { GoogleMapsModule } from '@angular/google-maps'
import { LocationComponent } from './location/location.component';
import { BingoComponent } from './bingo/bingo.component';
import { SponsorComponent } from './sponsors/sponsor.component';
import { SponsorService } from './service/sponsor.service';
import { VendorComponent } from './vendor/vendor.component';
import { VendorFormComponent } from './vendor/vendorform.component';
import { KioskComponent } from './kiosk/kiosk.component';
import { FutureEventKioskComponent } from './kiosk/future-events-kiosk.component';
import { KioskJackpotComponent } from './kiosk/jackpot-kiosk.component';
import { DonorsComponent } from './donors/donors.component';
import { ImpactComponent } from './impact/impact.component';
import { ImpactDetailComponent } from './impact/impact-detail.component';
import { BlogComponent } from './blog/blog.component';
import { BlogDetailComponent } from './blog/blog-detail.component';
import { ContentSesrvice } from './service/content.service';
import { SeatService } from './service/seating.service';
import { SeatingUpperComponent } from './seating/seatingupper.component';
import { SeatingLowerComponent } from './seating/seatinglower.component';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    CalendarPageComponent,
    ContactComponent,
    AboutComponent,
    EventsComponent,
    EventComponent,
    CartComponent,
    PrivacyComponent,
    DonorsComponent,
    JackpotComponent,
    LocationComponent,
    BingoComponent,
    SponsorComponent,
    VendorComponent,
    VendorFormComponent,
    KioskComponent,
    FutureEventKioskComponent,
    KioskJackpotComponent,
    ImpactComponent,
    ImpactDetailComponent,
    BlogComponent,
    BlogDetailComponent,
    SeatingLowerComponent,
    SeatingUpperComponent
  ],
  imports: [
    BrowserModule,
    FormsModule, 
    NgbModule.forRoot(),
    AppRoutingModule,
    FullCalendarModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReCaptchaModule,
    JwSocialButtonsModule,
    // GoogleMapsModule
  ],
  providers: [ 
    EventService,
    CartSesrvice,
    SponsorService,
    ContentSesrvice,
    SeatService
   ],
  bootstrap: [AppComponent]
})
export class AppModule { }


