import { Inject, Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Http, Request, Headers, Response } from '@angular/http';
import { Logger } from 'angular2-logger/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { eventNames } from 'cluster';


export interface sponsor {
    id: number,
    imageLink: string,
    contactName: string,
    contactPhone: string,
    companyName: string,
    webSite: string,
    facebook1: string,
    facebook2: string,
    acceptedTerms: boolean,
    createdDate: string,
    email: string,
    isPartner: boolean,
    displayText: string,
    imgWidth: number,
    imgHeight: number,
    paid: 0,
    displayOneSite: false
}

export interface evtvendor {
    eventId: number
    contactName: string,
    contactPhone: string,
    companyName: string,    
    email: string,    
    paid: number;
}

@Injectable()
export class SponsorService {

    constructor(private http: HttpClient) {
        
    }

    corporateSponsors = new ReplaySubject<any>(null);
    selectedSponsor = new ReplaySubject<sponsor>(null)

    public getCorporateSponsors() {
        this.http.get<Array<sponsor>>(`/api/partners`).subscribe(x => {
            this.corporateSponsors.next(x);
        },
            error => {
                console.error('Error extracting corporate sponsors')
            });
    }

    public getSponsorById(id: number) {
        
        this.http.get<sponsor>(`/api/partner/${id}`).subscribe(x => {
            this.selectedSponsor.next(x);
            console.log(x);
        },
            error => {
                console.error('Error extracting sponsor')
            });
    }

    public async saveSponsor(s: sponsor) {
        console.log(s);
        var promise = new Promise<any>((resolve, reject) => {
            this.http.post(`/api/sponsor`, s).subscribe(x => {
                console.log(x);
                resolve(x);
            },
                error => {
                    console.error('Error saving sponsor');
                    reject(error);
                });

        });

        return promise;
    }
}