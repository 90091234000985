import { Component, OnInit } from '@angular/core';
import { ContentSesrvice, Content  } from '../service/content.service';


@Component({
  selector: 'app-impact',
  templateUrl: './impact.component.html',
  styleUrls: ['./impact.component.css'],
})

export class ImpactComponent implements OnInit {

  loading = true;
  blogs: Array<Content> = [];
  community: Array<Content> = [];
  

  ngOnInit() {
    this.loading = true;
    this.contentService.getContent('impact').then(b => {
      this.blogs = b;

    }).then(() => {
      setTimeout(function () {
        this.loading = false;
      }.bind(this),
        500);
    });

    // this.contentService.getContent('Community').then(c => {
    //   this.community = c;
    //   this.loading = false;
    // });
  }

  // ngOnInit() { 
  //   this.contentService.getContent('impact').then(b => {
  //     this.blogs = b;
  //     this.loading = false;
  //   });
      
  //   this.contentService.getContent('Community').then(c => {
  //     this.community = c;
  //     this.loading = false;
  //   });
      
  // }


constructor(private contentService: ContentSesrvice) {


}

  getUrl(c: Content) {
    return this.contentService.makeUrl(c);
  }

}
 

