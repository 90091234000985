import { Inject, Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Http, Request, Headers, Response } from '@angular/http';
import { Logger } from 'angular2-logger/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BingoPackage, DiscountCoupon } from './event.service';
import { evtvendor } from './sponsor.service';


export interface Cart {
    stripeToken: string,
    total: number,
    playerInfos: Array<BingoPackage>
    cartOwner: string,
    cartEmail: string,
    message: string,
    howdidyouhear: string
}

export interface PaymentInfo {
    name: string,
    email: string,
    number: number,
    exp_month: number,
    exp_year: number,
    cvc: number,
    address_zip: string,
    message: string,
    howdidyouhear: string
}

export interface StripeAuth {
    name: string,
    number: number,
    exp_month: number,
    exp_year: number,
    cvc: number,
    address_zip: string,
}

export interface PlayerInfo {
    id: number,
    info: BingoPackage,
    vendor: evtvendor
}


@Injectable()
export class CartSesrvice {

    public players = new ReplaySubject<Array<PlayerInfo>>(null);
    message = new ReplaySubject<string>(null);
    public isEmptied = new ReplaySubject<boolean>(null);

    constructor(private http: HttpClient) {
        
        const cart = JSON.parse(localStorage.getItem('cart'));
        console.log(cart);
        if (cart) {
            this.players.next(cart);
        } else {
            this.players.next([]);
        }
        
        
        this.isEmptied.next(false);
    }

    public clearCart() {
        this.players.next([]);
        this.isEmptied.next(true);
        localStorage.removeItem('cart');
    }

}
