import { Component, OnInit, Input } from '@angular/core';
import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ViewChild } from '@angular/core';
import { ReCaptchaComponent } from 'angular2-recaptcha';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Http, Request, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs';


export interface ContactForm {
  name: string,
  email: string,
  message: string,
  human: boolean,
  ip: string,
}

export interface IPAddress {
  ip: string
}


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})

export class ContactComponent implements OnInit {

  @ViewChild(ReCaptchaComponent) captcha: ReCaptchaComponent;

  contactForm: ContactForm;
  showForm = true;
  showSent = false;
  showSpammer = false;
  showError = false;
  ipAddress: IPAddress;

  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  constructor(private http: Http,
    private httpClient: HttpClient) {


  }

  ngOnInit() {

    this.ipAddress = {
      ip: ''
    };


    this.cancelHandler();
    this.getUserIp();

  }

  getUserIp() {
    // https://api.ipify.org/?format=jsonp&callback=JSONP_CALLBACK
    this.httpClient.get<IPAddress>('https://api.ipify.org/?format=json').subscribe(x => {
      console.log(x);
      this.ipAddress = x;
      this.checkIP();
    }, error => {
      console.log('ip address Error', error);
      this.ipAddress.ip = 'not detected';
      this.showForm = false;
    });
  }

  checkIP() {
    console.log('Checking Client IP Address for Abuse');
    if (this.ipAddress.ip !== 'not detected') {

      this.http.get(`/api/contact/ip/${this.ipAddress.ip}`).subscribe(x => {
        console.log(x);
      }, error => {
        console.log('ip address Error', error);
        this.showForm = false;
        if (error.statusText === 'Bad Request') {
          this.showSpammer = true;
        } else {
          this.showError = true;
        }
      });
    }
  }


  recaptchaHandler(evt) {
    let token = this.captcha.getResponse();
    if (evt && token && evt === token) {
      this.contactForm.human = true;
    }
  }

  cancelHandler() {
    this.contactForm = {
      name: '',
      email: '',
      message: '',
      human: false,
      ip: ''
    }
    this.showForm = true;
    if (this.captcha) {
      this.captcha.reset();
    }

  }

  sendHandler() {
    console.log(this.contactForm);
    this.contactForm.ip = this.ipAddress.ip;
    this.showForm = false;
    this.http.post('/api/contact', this.contactForm).subscribe(x => {
      console.log(x);
      this.showSent = true;
    }, error => {
      console.log('send error');
      console.log(error);
      if (error.statusText === 'Bad Request') {
        this.showSpammer = true;
      } else {
        this.showError = true;
      }

    });


  }

}
