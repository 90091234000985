import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { CalendarComponent } from 'ng-fullcalendar';
import { Options } from 'fullcalendar';

import { EventService, Progressive, FutureEvent } from '../service/event.service';

import * as moment from "moment";


@Component({
  selector: 'app-jackpot-kiosk',
  templateUrl: './jackpot-kiosk.component.html',
  styleUrls: ['./kiosk.component.css'],
})

export class KioskJackpotComponent implements OnInit {

  moment = require('moment');
  today: Date;
  
  nextSunday: FutureEvent;
  nextWednesday: FutureEvent;
  nextFriday: FutureEvent;
  
  safeCrackerAmount: number;
  pickBlue: number = 0;
  pickRed: number = 0;
  pickGreen: number = 0;
  pickYellow: number = 0;
  bonanza: number = 0;

  constructor(protected eventService: EventService) {
    this.eventService.progressives.subscribe(p => {
      
      p.forEach(x => {

        if (x.name === 'Safe Cracker') {
          this.safeCrackerAmount = x.payout;
        }

        if (x.name === 'Pick-4 Blue') {
          this.pickBlue = x.payout;
        }

        if (x.name === 'Pick-4 Red') {
          this.pickRed = x.payout;
        }

        if (x.name === 'Pick-4 Green') {
          this.pickGreen = x.payout;
        }

        if (x.name === 'Pick-4 Yellow') {
          this.pickYellow = x.payout;
        }

        if (x.name === 'Bonanza') {
          this.bonanza = x.payout;
        }

      });
    });
   }

 

  
    async ngOnInit() { 

      
    }

    
    
}


    