import { Inject, Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Http, Request, Headers, Response } from '@angular/http';
import { Logger } from 'angular2-logger/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { eventNames } from 'cluster';
import { sponsor } from './sponsor.service';


export interface CalendarEvent
{
    id: number,
    title: string,
    briefDescription: string,
    url: string,
    start: Date,
    end: Date,
    allDay: boolean,
    editable: boolean,
    backgroundColor: string,
    borderColor: string,
    imagelink: string
}

export class TAEvent {
  id: number = 0;
  title: string = '';
  briefDescription: string = '';
  description: string = '';
  location: string = '';
  locationMap: string = '';
  startDateTime: Date = new Date('1/1/2000');
  endDateTime: Date = new Date('1/1/2000');
  site: string = '';
  delete: string = '';
  vendorInfo: string = '';
  eventPrice: number = 0;
  vendorPrice: number = 0;
  maxVendors: number = 0;
  maxAttendees: number = 0;
  eventType: number = 0;
  childPrice: number = 0;
  question1: string = '';
  question2: string = '';
  recurring: number = 0;
  showAttendBtn: string = '';
  showVendorBtn: string = '';
  showPaperSize: string = '';
  showExtraBook: string = '';
  showQuickdraw: string = '';
  showDeal: string = '';
  showMoney: string = '';
  showPlinko: string = '';
  showJackpot: string = '';
  showSafe: string = '';
  showTicTac: string = '';
  showDiscount: string = '';
  showHardcard: string = '';
  showSpecial: string = '';
  showPlay4: string = '';
  kidPrice: number = 0;
  vipPrice: number = 0;
  showKidPayment: string = '';
  showVipPayment: string = '';
  showDonationBtn: string = '';
  showWinnersChoice: string = '';
  showButterfly: string = '';
  showLeprechaun: string = '';
  showMiniJackpot: string = '';
  showWackyWheel: string = '';
  showPrimaryComputer: string = '';
  showComputerAsExtra: string = '';
  computerSizeData: string = '';
  showWizardofOz: string = '';
  imagelink: string = '';
  showSpeedball: string = '';
  paperSizeData: string = '';
  showUpperSeating: string = '';
  showLowerSeating: string = '';
}

export interface FutureEvent
{
    id: number,
    title: string,
    briefDescription: string,
    description: string,
    locaiton: string,
    locationMap: string,
    start: Date,
    endDateTIme: Date,
    site: string,
    delete: string,
    vendorInfo: string,
    eventPrice?: number,
    vendorPrice: number,
    maxVendors?: number,
    maxAttendees?: number,
    eventType: number,
    childPrice: number,
    question1: string,
    question2: string,
    recurring?: number,
    showAttendBtn: string,
    showDonationBtn: string,
    showWinnersChoice: string,
    imagelink: string
}

export interface BingoPackage {
  id: number,
  cartId: number,
  packageName: string,
  firstName: string,
  lastName: string,
  email: string,
  eventId: number,
  eventName: string,
  total: number;
  stripeToken: string,
  packageQuantity: number,
  extraBook: number,
  quickdraw: number,
  deal: number,
  money: number,
  plinko: number,
  jackpot: number,
  safe: number,
  punch2Win: number,
  ticTac: number,
  discount: number,
  hardcard: number,
  message: string,
  special: number,
  play4: number,
  butterfly: number,
  leprechaun: number,
  miniJackpot: number,
  wackyWheel: number,
  computer: boolean,
  paperSize: number,
  playercard: string,
  tickets: Array<TicketPurchase>,
  howDidYouHear: string,
  winnersChoice: string, 
  computerSize: number,
  wizardofoz: number,
  speedball: number,
  seatoption: string
}



export interface DiscountCoupon {
  title: string,
  discountAmount: number,
  userEmail: string,
  startDate: Date,
  endDate: Date
}

export enum EventType {
    Bingo = 0,
    GeneralAdmission = 1,
    Sunday = 2,
    Wednesday = 3,
    Friday = 4,
    HighStakes = 5,
    Virtual = 6,
    Monday = 7,
    Tuesday = 8,
    Thursday = 9,
    Saturday = 10
}

export interface Progressive {
    id: number,
    name: string,
    payout: number
}

export interface SealTicket {
  id: string,
  saleDate: string,
  eventBooksId: string,
  name: string,
  serialNumber: string,        
  ticketCount: number,
  ticketSealPayout: number,       
  onlinesales: number,
}

export interface TicketPurchase {  
  name: string,
  quantity: number,
}

@Injectable()
export class EventService {

    constructor(private http: HttpClient) {
        this.getCalendarEvents();
        this.getFutureEvents();
        this.getOrganDonors();
        this.getProgressives();
        this.getVersionInfo();
    }

    calendarEvents = new ReplaySubject<any>(null);
    futureEvents = new ReplaySubject<Array<FutureEvent>>(null);
    selectedEvent = new ReplaySubject<TAEvent>(null)
    haveComputers = new ReplaySubject<boolean>(null);
    availableTickets = new BehaviorSubject<Array<SealTicket>>(null);
    organDonors = new ReplaySubject<Array<string>>(null);
    progressives = new ReplaySubject<Array<Progressive>>(null);
    release = new ReplaySubject<string>(null);
    availablePlayerCount = new ReplaySubject<number>(null);
    vendors = new ReplaySubject<Array<sponsor>>(null);
    

  emptyPlayer() {
    return {
      id: 0,
      cartId: 0,
      packageName: '',
      firstName: '',
      lastName: '',
      email: '',
      eventId: 0,
      eventName: '',
      total: 0,
      stripeToken: '',
      packageQuantity: 1,
      extraBook: 0,
      quickdraw: 0,
      ticTac: 0,
      deal: 0,
      money: 0,
      plinko: 0,
      jackpot: 0,
      safe: 0,
      punch2Win: 0,
      discount: 0,
      hardcard: 0,
      special: 0,
      message: '',
      play4: 0,
      butterfly: 0,
      leprechaun: 0,
      miniJackpot: 0,
      wackyWheel: 0,
      paperSize: 0,
      computer: false,
      playercard: '',
      tickets: [],
      howDidYouHear: '',
      winnersChoice: 'No',
      computerSize: 0,
      wizardofoz: 0,
      speedball: 0,
      seatoption: ''
    }
  }
  
    public getCalendarEvents() {
        this.http.get<Array<any>>(`/api/events/calendar`).subscribe(x => {
            this.calendarEvents.next(x);
          },
          error => {
            console.log('Error extracting calendar events')
          });
    }

    public getFutureEvents() {
        this.http.get<Array<FutureEvent>>(`/api/events/future`).subscribe(x => {
            this.futureEvents.next(x);
          },
          error => {
            console.log('Error extracting future events')
          });
  }
  
  public getTodaysEvent() {
    this.http.get<TAEvent>(`/api/todayevents`).subscribe(x => {
      this.selectedEvent.next(x);
    },
      error => {
        console.error('Error extracting todays event');
      });
  }



  public getEventById(id: number) {
    this.availableTickets.next(null);
    this.http.get<TAEvent>(`/api/events/${id}`).subscribe(x => {
      // console.warn(x);
          this.selectedEvent.next(x);
          // console.log(x);
          //this.getAvailableTickets(id);
          //this.getEventremianingPlayercountCount(id);
          },
          error => {
            console.error('Error extracting events')
          });
  }

  public getEventremianingComputerCount(id: number) {
    
    this.http.get<number>(`/api/events/${id}/remianingcomputers`).subscribe(x => {
      console.log(`Remaining Computers: ${x}`);
      if (x < 1) {
        this.haveComputers.next(false);
      }
      else {
        this.haveComputers.next(true);
      }
    },
      error => {
        console.log('Error extracting Computer Count')
      });
  }

  public getEventremianingPlayercountCount(id: number) {
    let promise = new Promise<number>((resolve, reject) => {
      this.http.get<number>(`/api/events/${id}/playercount`).subscribe(x => {
        console.log(`Remaining Players: ${x}`);
        this.availablePlayerCount.next(x);
        resolve(x);
      },
        error => {
          console.log('Error extracting Computer Count');
          reject(error);
        });
    });
    return promise;
    
  }

  public getAvailableTickets(id: number) {
    this.http.get<Array<any>>(`/api/events/${id}/availabletickets`).subscribe(x => {
      console.log(x);
      this.availableTickets.next(x);
    },
      error => {        
        console.error('Error Available Tickets');
        console.error(error);
      });
  }

    public getEventType(n: number) {
        return EventType[n];
    }

  //https://localhost:44397/api/events/529/showcomputer
    

    public getOrganDonors() {
        this.http.get<Array<any>>(`/api/events/organdonors`).subscribe(x => {
            this.organDonors.next(x);
            console.log('Succesfully retrieved organ donors');
        },
            error => {
                console.log('Error extracting organ donors');
            });
    }

    public getProgressives() {
        this.http.get<Array<Progressive>>(`/api/events/progressives`).subscribe(x => {
            this.progressives.next(x);
            console.log('Succesfully retrieved progressives');
        },
            error => {
                console.log('Error extracting progressives');
            });
    }

    getVersionInfo() {
        this.http.get<any>(`/api/version`).subscribe(x => {
            console.log('App Version', x);
            this.release.next(x.release);            
        },
            error => {
                console.log('Error extracting app version');
                console.error(error);
            });
  }
  
  
  public getEventVendors(id: number) {
    console.log(id);
    this.http.get<Array<sponsor>>(`/api/event/${id}/vendors`).subscribe(x => {
      console.log(x);
      this.vendors.next(x);
      console.log('Succesfully retrieved vendors');
    });
  }

}
