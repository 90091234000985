import { Component, OnInit, ViewChild } from '@angular/core';
import { CalendarComponent } from 'ng-fullcalendar';
import { Options } from 'fullcalendar';

import { EventService } from 'src/app/service/event.service';
import { zip } from 'rxjs';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css'],
})

export class EventsComponent implements OnInit {

  loading = false;
  events = [];
  calendarOptions: Options;
  displayEvent: any;
  @ViewChild(CalendarComponent) ucCalendar: CalendarComponent;

  constructor(protected eventService: EventService) {
    this.loadEvents();
   }

  
    ngOnInit() { 

      this.eventService.futureEvents.subscribe(evts => {
        this.events = evts;
        
          setTimeout(function() {
          this.loading = false
      
          }.bind(this),
          500);

          
      });

    }

    
    clearEvents() {
      this.events = [];
    }

    loadEvents() {
      this.loading = true;
      this.eventService.futureEvents.subscribe(data => {
        this.events = data;
      });
    }
    

  clickButton(model: any) {
    this.displayEvent = model;
  }
  
  eventClick(model: any) {
    model = {
      event: {
        id: model.event.id,
        start: model.event.start,
        end: model.event.end,
        title: model.event.title,
        allDay: model.event.allDay
        // other params
      },
      duration: {}
    }
    this.displayEvent = model;
  }
  updateEvent(model: any) {
    model = {
      event: {
        id: model.event.id,
        start: model.event.start,
        end: model.event.end,
        title: model.event.title
        // other params
      },
      duration: {
        _data: model.duration._data
      }
    }
    this.displayEvent = model;
  }

  showbg(item: any) {
    if (item.imagelink && item.imagelink !== '') {
      return item.imagelink;
    }
    return '/assets/img/bingoboards.jpg';
  }
}


    