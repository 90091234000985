import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { EventService, TAEvent, BingoPackage, EventType,  Progressive, FutureEvent } from 'src/app/service/event.service';
import * as moment from "moment";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [NgbCarouselConfig] // add NgbCarouselConfig to the component providers
})

export class HomeComponent implements OnInit {

  

  ngOnInit() { 
      // //  Code formatting script
      //  $.getScript('./assets/js/prism.min.js');
      
    
  }
  // constructor(config: NgbCarouselConfig) {
  //   // customize default values of carousels used by this component tree
  //   config.interval = 10000;
  //   config.wrap = false;
  //   config.keyboard = false;
  // }

  constructor() {

  }


}
