import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { EventService } from '../service/event.service';
import { sponsor, SponsorService } from '../service/sponsor.service';


@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html'
})

export class VendorComponent  {

  vendors: Array<sponsor> = [];
  eid: number;
 
  constructor(private sponsorService: SponsorService,
  private eventService: EventService) {
    
  }
  
  @Input()
  set eventId(e: number) {
    this.eventService.getEventVendors(e);
    this.eid = e;
    this.eventService.vendors.subscribe(v => {
      this.vendors = v;
      console.log(this.vendors);
    });
  }

  vendorSignup() {

  }
}


    