import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})

export class HeaderComponent implements OnInit {

  loading = true;
  url = "https://www.statestreetbingo.com";
  text = 'State Street Bingo where having fun in the number one rule';
  imageUrl = 'https://www.statestreetbingo.com/assets/img/header.png';

  
  ngOnInit() { 
    this.loading = false;
      
    
  }


  constructor() {

    

  }

  


}
 

