import { Component, OnInit, ViewChild } from '@angular/core';
import { sponsor, SponsorService } from '../service/sponsor.service';


@Component({
  selector: 'app-sponsor',
  templateUrl: './sponsor.component.html'
})

export class SponsorComponent implements OnInit {

  partners: Array<sponsor> = [];
 
  constructor(private sponsorService: SponsorService) {
    this.sponsorService.getCorporateSponsors();
  }
  
  ngOnInit() {
    this.loadSponsors();
  }

  private loadSponsors() {
    this.sponsorService.corporateSponsors.subscribe(x => {
      this.partners = x;
      // console.log(x);
      // console.log(this.partners);
    });
  }
}


    