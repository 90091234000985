
import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CalendarPageComponent } from './calendar/calendar.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { EventsComponent } from './events/events.component';
import { EventComponent } from './event/event.component';
import { CartComponent } from './cart/cart.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { LocationComponent } from './location/location.component';
import { BingoComponent } from './bingo/bingo.component';
import { SponsorComponent } from './sponsors/sponsor.component';
import { VendorFormComponent } from './vendor/vendorform.component';
import { KioskComponent } from './kiosk/kiosk.component';
import { DonorsComponent } from './donors/donors.component';
import { ImpactComponent } from './impact/impact.component';
import { BlogComponent } from './blog/blog.component';
import { BlogDetailComponent } from './blog/blog-detail.component';
import { ImpactDetailComponent } from './impact/impact-detail.component';

//import { AuthGuard } from './shared/auth/auth-guard.service';

const appRoutes: Routes = [
  
  { path: "", redirectTo: "/home", pathMatch: "full" },
  { path: "home", component: HomeComponent },
  { path: "calendar", component: CalendarPageComponent },
  { path: "about", component: AboutComponent },
  { path: "contact", component: ContactComponent },
  { path: "events", component: EventsComponent },
  { path: "event", component: EventComponent },
  { path: "event/:id", component: EventComponent },
  { path: "cart", component: CartComponent },
  { path: "checkout", component: CartComponent },
  { path: "privacy", component: PrivacyComponent },
  { path: "location", component: LocationComponent },
  { path: "bingo", component: BingoComponent },
  { path: "lifepartners", component: SponsorComponent },
  { path: "vendor/:id", component: VendorFormComponent },
  { path: "kiosk", component: KioskComponent },
  { path: "donors", component: DonorsComponent },
  { path: "community", component: ImpactComponent },
  { path: "news", component: BlogComponent },
  { path: "blog/:id", component: BlogDetailComponent },
  { path: "impact/:id", component: ImpactDetailComponent },
 
  
  
//   { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES, canActivate: [AuthGuard] },
//   { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES, canActivate: [AuthGuard] },
];


@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { enableTracing: true, errorHandler: console.error, useHash: false })],
    exports: [RouterModule]
  })
  export class AppRoutingModule {
  
  }
