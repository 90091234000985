import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { EventService, FutureEvent, Progressive } from '../service/event.service';


@Component({
  selector: 'app-future-events-kiosk',
  templateUrl: './future-events-kiosk.component.html',
  styleUrls: ['./kiosk.component.css'],
})

export class FutureEventKioskComponent {

 
  futureEvents = [];
  mtop: number = 500;
  scroll1: string = "margin-top: 500px";
  
  constructor(protected eventService: EventService) {
    
    this.eventService.futureEvents.subscribe(data => {
      let count = 0;
      this.futureEvents = [];
      data.sort((a, b) => a.start < b.start ? -1 : a.start > b.start ? 1 : 0).forEach(e => {
        if (count < 5) {
          this.futureEvents.push(e);
          count++;
        }
      });
      //this.futureEvents = data.sort((a, b) => a.start < b.start ? -1 : a.start > b.start ? 1 : 0);
      console.log(this.futureEvents)
      
    });

    // setInterval(() => {
    //   this.mtop = this.mtop - 10;
    //   if (this.mtop < 20) {
    //     this.mtop = 400;
    //   }
    //   this.scroll1 = `margin-top: ${this.mtop}px`;
    //   console.log(this.scroll1);
    // }, 1000);

   }

  
  

    
    
}


    