import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { EventService, TAEvent, BingoPackage, EventType,  Progressive, FutureEvent } from 'src/app/service/event.service';
import * as moment from "moment";

@Component({
  selector: 'app-bingo',
  templateUrl: './bingo.component.html',
  styleUrls: ['./bingo.component.css'],
  providers: [NgbCarouselConfig] // add NgbCarouselConfig to the component providers
})

export class BingoComponent implements OnInit {

  loading = true;
  nextSunday: FutureEvent;
  nextWednesday: FutureEvent;
  nextFriday: FutureEvent;
  progressives: Array<Progressive> = [];
  isLastFriday = false;


  ngOnInit() { 
      //  Code formatting script
       $.getScript('./assets/js/prism.min.js');
      this.loadEvents();
    this.loading = false;  // Added when commented out
  }
  constructor(config: NgbCarouselConfig, protected eventService: EventService) {
    // customize default values of carousels used by this component tree
    config.interval = 10000;
    config.wrap = false;
    config.keyboard = false;
  }

  async loadEvents() {
    this.loading = true;

    this.eventService.progressives.subscribe(o => {
      this.progressives = o;
      console.log(o);
    });

    await this.eventService.futureEvents.subscribe(data => {
      
      this.nextSunday = data.find(x => x.title === 'Sunday Night Cash Bingo');
      this.nextWednesday = data.find(x => x.title === 'Wednesday Night Cash Bingo');
      this.nextFriday = data.find(x => x.title === 'Friday Night Cash Bingo');

      console.log(moment(this.nextFriday.start).toDate().getDate());
      const lastDay = moment(this.nextFriday.start).endOf('month').endOf('day');
      const lastFriday = lastDay.subtract((lastDay.day() + 2) % 7, 'days');
      console.log(lastFriday.toDate().getDate());

      if (moment(this.nextFriday.start).toDate().getDate() === lastFriday.toDate().getDate()) {
      this.isLastFriday = true;
      }
      this.loading = false;
    });
    

    
  }



}
