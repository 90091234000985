
import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

var fireRefreshEventOnWindow = function () {
    var evt = document.createEvent("HTMLEvents");
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent  {

    location: Location;
    title = 'BINGO';
    update: boolean = false;

    constructor(private elementRef: ElementRef,
        private router: Router,
        private http: HttpClient) { 
        console.log(this.router.url);
        console.log(window.location);

        console.log(window.location.protocol.toString());
        if (window.location.protocol.toString() === 'http:') {
            window.location.href = window.location.href.replace('http', 'https');
        } 

        this.getVersionInfo();

        // if (!window.location.href.startsWith('https://www.') && window.location.hostname !== 'localhost') {
        //     window.location.href = window.location.href.replace('https://', 'https://www.');
        // } 

        
    }  

    getVersionInfo(): any {

        let promise = new Promise<any>((resolve, reject) => {
            this.http.get<any>(`/api/version`).subscribe(x => {
                console.warn(x);

                let lastrefresh = localStorage.getItem("lastrefresh");
                console.log('lastrefresh', lastrefresh);

                if (lastrefresh === null || !lastrefresh || x.release !== lastrefresh) {
                    localStorage.setItem('lastrefresh', x.release);
                    this.update = true;
                    // this.modalsvc.open(this.updatemodal);
                    setTimeout(async () => {
                        window.location.reload();
                    }, 2000);

                }

                //this.appService.release.next(x.release);
            },
                error => {
                    console.error('Error extracting app version');
                    console.error(error);
                });
        });
        return promise;
    }
}