import { Component, EventEmitter, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { url } from 'inspector';
import { CartSesrvice } from '../service/cart.service';
import { ChangeDetectionStrategy } from '@angular/compiler/src/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  

  onNavigationSelection = new EventEmitter<string>();
  // homeClass = '';
  // calendarClass: string;
  // aboutClass = '';
  // contactClass = '';
  // eventsClass = '';

  cartCount: number = 0;

  navCss: string = "navbar-collapse collapse";

  constructor(private router: Router,
    private cd: ChangeDetectorRef,
  private cartService: CartSesrvice) {
    
    

    // this.cartService.isEmptied.subscribe(b => {
    //   if (b) {
    //     console.log('Cart Cleared');
    //     this.cartCount = 0;
    //     this.cartService.isEmptied.next(false);
    //   }
    //   this.cd.detectChanges();
    // });

 
  }

  ngOnInit() {
    // this.cartService.players.subscribe(c => {
    //   this.cartCount = c.length;
      
    // });

    this.cartService.players.subscribe(c => {
      console.log(c);
      this.cartCount = c.length;
      this.cd.detectChanges();
    });
    
  }

  navigationHandler() {
    
    if (this.navCss == "navbar-collapse collapse") {
      this.navCss = "navbar-collapse collapse in";
    } else {
      this.navCss = "navbar-collapse collapse";
    }
  }
}

