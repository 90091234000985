import { Component, OnInit } from '@angular/core';
import { Progressive, EventService } from '../service/event.service';

@Component({
  selector: 'app-jackpots',
  templateUrl: './jackpot.component.html',
  styleUrls: ['./jackpot.component.css'],
})

export class JackpotComponent implements OnInit {

  loading = true;
  progressives: Array<Progressive> = [];
  
    ngOnInit() { 
  
        
    }
  
  constructor(protected eventService: EventService) {
    this.eventService.progressives.subscribe(o => {
      console.log(o);
      this.progressives = o;
    });
  }
}
