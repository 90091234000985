import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Seat, SeatService } from '../service/seating.service';
import { a } from '@angular/core/src/render3';



@Component({
    selector: 'app-lowerseating',
    templateUrl: './seatinglower.component.html',
    styleUrls: ['./seating.css']
})

export class SeatingLowerComponent {

    lowerseats: Array<Seat> = []
    seat1style = "padding-left: 13px; cursor: pointer;"
    selected: Seat = new Seat;
    //eventid: number = 0;
    // selectedEventSeats: Array<string> = [];

    @Output()
    selectedseat = new EventEmitter<Seat>();

    @Input()
    eventid: number = 0;

    @Input()
    existingseat: string = '';

    @Input()
    set seatingoption(s: Seat) {
        console.error('xx---xx---xx---xx---xx');
        console.log(s);
        this.selected = s;

        if (!this.selected) {
            this.selected = this.ss.emptyseat();
        }

        this.ss.fetchselectedseats(this.eventid);
        this.lowerseats = this.ss.emptyLowerLevel();
        this.ss.fetchtempseats(this.eventid);
        this.ss.selectedeventseats.subscribe(seats => {
            seats.forEach(s => {
                let seat = this.convertStrtoSeat(s, false);
            });
        });
        this.ss.selectedtempseats.subscribe(seats => {
            seats.forEach(s => {
                console.log(s);
                let seat = this.convertStrtoSeat(s, true);
            });
        });

    }


    constructor(private ss: SeatService) {
        this.lowerseats = this.ss.emptyLowerLevel();
    }

    reloadhandler() {
        this.lowerseats = this.ss.emptyLowerLevel();
        this.ss.fetchselectedseats(this.eventid);
        this.ss.fetchtempseats(this.eventid);
        this.ss.selectedeventseats.subscribe(seats => {
            seats.forEach(s => {
                let seat = this.convertStrtoSeat(s, false);
            });
        });
        this.ss.selectedtempseats.subscribe(seats => {
            seats.forEach(s => {
                let seat = this.convertStrtoSeat(s, true);
            });
        });

    }

    rowfilter(r: string) {
        return this.lowerseats.filter(x => x.row === r)
    }

    convertStrtoSeat(seatoption: string, hide: boolean) {
        console.log(seatoption);
        if (seatoption) {
            let p1 = seatoption.split('-');
            const level = p1[0];

            let p2 = p1[1].split(':');
            let row = p2[0];
            let sn = Number(p2[1]);


            if (level === 'L') {
                let s = this.lowerseats.find(x => x.row === row && x.seatNo === sn);
                if (s) {
                    s.occupied = 'Yes';
                    if (s.row === 'A' || s.row === 'C' || s.row === 'E' || s.row === 'G' || s.row === 'I' || s.row === 'K' || s.row === 'M' || (s.row === 'J' && s.seatNo < 5) || (s.row === 'B' && s.seatNo < 5)) {
                        if (Math.abs(s.seatNo) % 2 === 1) {
                            if (hide) {
                                s.image = '/assets/seating/left-facing-forward-grey.png'
                            }
                            else {
                                s.image = '/assets/seating/left-facing-forward-red.png'
                            }
                        }
                        else {
                            if (hide) {
                                s.image = '/assets/seating/right-facing-forward-grey.png'
                            }
                            else {
                                s.image = '/assets/seating/right-facing-forward-red.png'
                            }
                        }

                    } else {
                        if (Math.abs(s.seatNo) % 2 === 1) {
                            if (hide) {
                                s.image = '/assets/seating/left-facing-backward-grey.png'
                            }
                            else {
                                s.image = '/assets/seating/left-facing-backward-red.png'
                            }  
                        }
                        else {
                            if (hide) {
                                s.image = '/assets/seating/right-facing-backward-grey.png'
                            }
                            else {
                                s.image = '/assets/seating/right-facing-backward-red.png'
                            } 
                        }
                    }
                    return s;
                }

            }


            return new Seat;
        }



    }


    seatclickHandler(s: Seat) {
        console.log(s);
        console.log(this.existingseat);
        let sel = `${s.level}-${s.row}:${s.seatNo}`

        this.ss.selectedseat.subscribe((x: any) => {
            console.log(x);
            this.selected = x;
            console.log(this.selected);
        });
        console.log(this.selected);
        if (sel === this.existingseat || s.row === this.selected.row && s.seatNo === this.selected.seatNo && s.occupied === 'Yes') {
            console.log('Clear Seat')
            
            s.occupied = 'No';
            this.existingseat = '';
            s.row
            s.image = `/assets/seating/lower/${s.row}-${s.seatNo}.png`;

            this.selectedseat.emit(this.ss.emptyseat());
            this.ss.cleartempseat(this.eventid, sel);
        }
        else if (s.row !== this.selected.row && s.seatNo !== this.selected.seatNo && s.occupied === 'Yes') {
            alert('Seat is taken!');
        }
        else if (this.existingseat === '') {
            console.log('Select new seat');
            let sss = this.lowerseats.find(x => x.row === s.row && x.seatNo === s.seatNo && x.level === 'L');
            console.log(sss);

            if (!sss || sss.occupied === 'No') {
                s.occupied = 'Yes';
                if (s.row === 'A' || s.row === 'C' || s.row === 'E' || s.row === 'G' || s.row === 'I' || s.row === 'K' || s.row === 'M' || (s.row === 'J' && s.seatNo < 5) || (s.row === 'B' && s.seatNo < 5)) {
                    s.image = '/assets/seating/left-facing-forward-red.png'
                } else {
                    s.image = s.image = '/assets/seating/left-facing-backward-red.png'
                }

                this.selectedseat.emit(s);
                this.ss.savetempseat(this.eventid, sel);
            }
            else {
                alert('Seat is taken!');
            }

            
        }
        else {
            alert('You can only select one seat per player.');
        }
    }

}



