import { Component, OnInit, Input } from '@angular/core';
import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ViewChild } from '@angular/core';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Http, Request, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs';



@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css'],
})

export class LocationComponent implements OnInit {
  
  constructor() {
  }

  zoom = 15;
  // center: google.maps.LatLngLiteral;
  // options: google.maps.MapOptions = {
  //   mapTypeId: 'roadmap',
  //   zoomControl: true,
  //   scrollwheel: true,
  //   disableDoubleClickZoom: false    
  // }

  markers = [];


  ngOnInit() {
    //40.261293622105086, -75.65097245349567
    this.markers = [];
    // navigator.geolocation.watchPosition(position => {
    //   this.center = {
    //     lat: 40.261293622105086,
    //     lng: -75.65097245349567,
    //   }
    // });
    //this.addMarker();
  }

  //https://timdeschryver.dev/blog/google-maps-as-an-angular-component
  

  // addMarker() {
  //   this.markers.push({
  //     position: {        
  //       lat: 40.261293622105086,
  //       lng: -75.65097245349567,
  //     },
  //     label: {
  //       color: 'green',
  //       text: 'State Street Bingo',
  //     },
  //     title: 'Marker title ' + (this.markers.length + 1),
  //     info: 'Marker info ' + (this.markers.length + 1),
  //     options: {
  //       animation: google.maps.Animation.BOUNCE,
  //     },
  //   })
  // }
  

}
