import { Component, OnInit } from '@angular/core';
import { ContentSesrvice, Content  } from '../service/content.service';


@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css'],
})

export class BlogComponent implements OnInit {

  loading = true;
  blogs: Array<Content> = [];
  

  ngOnInit() { 
    this.loading = true;
    this.contentService.getContent('blog').then(b => {
      this.blogs = b;

    }).then(() => {
      setTimeout(function () {
        this.loading = false;
      }.bind(this),
        500);
    });   
    
    
  }


constructor(private contentService: ContentSesrvice) {


}

  getUrl(c: Content) {
    return this.contentService.makeUrl(c);
  }

}
 

