import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as moment from "moment";
import { interval } from 'rxjs';
import { FutureEvent, EventService, TAEvent } from '../service/event.service';

@Component({
  selector: 'app-kiosk',
  templateUrl: './kiosk.component.html',
  styleUrls: ['./kiosk.component.css'],
})

export class KioskComponent implements OnInit, OnDestroy {

 
  lingo: string;
  lingCounter = 0;
  maxlingoCount: number; 
  todaysEvent: TAEvent;

  lingos = [
    "Please Yell BINGO LOUD so we hear you! Before the next number is called.",
    "<p>Did you know?</p> Playing bingo can keep your brain in trim? According to Southampton University, bingo players are more mentally agile than non-bingo players. It is also suggested that social activities, such as playing bingo, is just as good for your health as doing physical exercise.",
    "<p>We call numbers every 15 seconds.</p><p>SpeedBall every 1 second.</p><p>Pick-4 every 17 seconds</p>",
    "Please raise your hand so we can verify your bingo",
    "<p>Did you know?</p> Bingo can be traced back to 1530, to an Italian lottery game, and the game is played on a Saturday in Italy, to this day.",
    "People of all ages play bingo but it has the reputation of being an older person's game. The majority of players who participate in the game are, in fact, below 35 years of age.",
    "Please clean your trash from the table at the end of the night.",
    "<p>Did you know?</p> The very first official fundraising bingo game was played in <br/>Wilkes-Barre in Pennsylvania.",
    "Have you ever wondered how long it takes for the normal bingo game to play? Start watching the next time you play, because it’s estimated that the bingo game lasts from three to six minutes.",
    "Please put your phone on vibrate",
    "<p>Did you know?</p> The more boards you're playing, then the bigger chance you have of winning? <p>But Do Not play more than you can handle! - It will just stress you out</p>",
    "With over 8% of the world’s population playing the game, Bingo is definitely the most popular game even in the coldest place on earth, and is enjoyed by millions.",
    "<p>Did you know?</p> Bingo can relieve stress? The game has shown significant effects in approving player’s cognitive abilities too. This is a one of the top bingo facts!",
    "According to statistics, 20% of Bingo players are men. That means 80% of you reading this are women",
    "<p>Did you know?</p> Bingo is the number one fundraiser for charities across the world.",
    "<p>Fact</p> 90% of the world play and enjoy bingo, and it’s known as the most popular past time.",
    "<p>Did you know?</p> The famous Bing Crosby even got involved in bingo; his childhood nickname was, yup, Bingo!",
    "Bingo cards were used by the Germans in 1800s as an educational tool designed to help the students to learn multiplication tables easily",
    "<p>Did you know?</p> Nearly half of all regular bingo players play the game every day?",
    "Thank you for playing Bingo. We appreciate you and your support for our foundation",
    "<p>Did you know?</p> There are about 552-million possible number combinations for a single bingo board.",
    "The bingo game was originally called “beano” but was later changed to “bingo” when an excited woman accidentally yelled the word after winning the jackpot",
    "<p>Did you know?</p> There are over 5000 people in our area waiting for an organ transplant?",
    "Winning the jackpot ranks fifth in the reasons why players join bingo games. It turned out that the top priority of bingo players is to find enjoyment",
    "<p>Did you know?</p> Cancer patients can be a donor; <p>Your skin, your bones or your cornia can all be used.</p>",

  ];
  
  constructor(protected eventService: EventService) {
    
   }

  ngOnDestroy() {
      clearInterval();
  }
  
  async ngOnInit() { 

    this.eventService.getTodaysEvent();
    this.eventService.selectedEvent.subscribe(t => {
      this.todaysEvent = t;
      console.log(this.todaysEvent);
    });

    
    this.maxlingoCount = this.lingos.length;
    let index = 0;
    setInterval(() => {
      if (index >= this.maxlingoCount) {
        index = 0;
      }
      this.lingo = this.lingos[index];
      index = index + 1;
      
    }, 6000);
  }    
}


    