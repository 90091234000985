import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartSesrvice, PlayerInfo } from '../service/cart.service';
import { EventService, TAEvent } from '../service/event.service';
import { evtvendor, sponsor, SponsorService } from '../service/sponsor.service';


@Component({
  selector: 'app-vendor-form',
  templateUrl: './vendorform.component.html'
})

export class VendorFormComponent implements OnInit, AfterViewInit, OnDestroy {

  vendors: Array<sponsor> = [];
  selectedEvent: TAEvent;
  id: number;
  loading: boolean = false;
  eventDateInPast: boolean = false;
  
  private selectedSponsor: evtvendor = {
    eventId: 0,
    contactName: "",
    contactPhone: "",
    companyName: "",
    email: "",
    paid: 0
  };
 
  constructor(
    protected eventService: EventService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private cartService: CartSesrvice,
    private http: HttpClient,
    private router: Router,
    private sponsorService: SponsorService) {
    this.eventService.selectedEvent.subscribe(e => {
      this.selectedEvent = e;
    });
  }
  

  ngAfterViewInit() {
    console.log("After Init");
  }

  ngOnDestroy() {
    console.log("Destry");
  }

  ngDoCheck() {
    console.log("Do check");
  }

  async ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params["id"] || "";
      
    });
    this.loading = true;
    this.eventService.getEventById(this.id);
    this.eventService.selectedEvent.subscribe(e => {
      this.selectedEvent = e;
      console.log(e);
      this.loading = false;
    });
  
  }

  

  addToCartHandler() {
    console.log('Vendor:', this.selectedSponsor);
   
    this.selectedSponsor.paid = this.selectedEvent.vendorPrice;
    this.selectedSponsor.eventId = this.selectedEvent.id;

    let cart: Array<PlayerInfo> = [];
    this.cartService.players.subscribe(x => cart = x);
    console.log(cart);
    let okToContinue = true;   
    let id = cart.length + 1;

    cart.push({ id: id, info: null, vendor: this.selectedSponsor });
    console.log(cart);
    this.cartService.players.next(cart);
    localStorage.setItem('cart', JSON.stringify(cart));
    location.href = '/cart';
    

    // this.cartService.players.subscribe(x => {
    //   console.log(x);
    //   if (x.length > 0) {
    //     this.showGoToCartButton = true;
    //     this.clearPlayerHandler();
    //   }
    // });

    
  }

}


    