import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";

export class Seat{
    level: string;
    row: string;
    seatNo: number;
    occupied: string;
    image: string;
    playeremail: string;
    playername: string;
    hide: boolean;
    padding: string;
}

export class EventSeat {
    eventid: number = 0;
    seat: Seat = new Seat;
}

export class TempSeat {
    id: number = 0;
    eventid: number = 0;
    seat: string = '';
    created: Date = new Date;
}

@Injectable()
export class SeatService {

    upperlevel: Array<Seat> = [];

    selectedseat = new EventEmitter<Seat>();
    selectedtempseats = new ReplaySubject<Array<string>>();


    constructor(private http: HttpClient) {
        this.selectedeventseats.next([])
        this.upperlevel = this.emptyUpperLevel();
    }

    selectedeventseats = new ReplaySubject<Array<string>>();

    emptyseat() {
        return {
            level: '',
            row: '',
            seatNo: 0,
            occupied: '',
            image: '',
            playeremail: '',
            playername: '',
            hide: false,
            padding: ''
        }
    }

    emptyUpperLevel() {
        return [
            {
                level: 'U',
                row: 'A',
                seatNo: 1,
                occupied: 'No',
                image: '/assets/seating/upper/A-1.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'U',
                row: 'A',
                seatNo: 2,
                occupied: 'No',
                image: '/assets/seating/upper/A-2.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'A',
                seatNo: 3,
                occupied: 'No',
                image: '/assets/seating/upper/A-3.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'A',
                seatNo: 4,
                occupied: 'No',
                image: '/assets/seating/upper/A-4.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '8px'
            },
            {
                level: 'U',
                row: 'A',
                seatNo: 5,
                occupied: 'No',
                image: '/assets/seating/upper/A-5.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '20px'
            },
            {
                level: 'U',
                row: 'A',
                seatNo: 6,
                occupied: 'No',
                image: '/assets/seating/upper/A-6.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'A',
                seatNo: 7,
                occupied: 'No',
                image: '/assets/seating/upper/A-7.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'A',
                seatNo: 8,
                occupied: 'No',
                image: '/assets/seating/upper/A-8.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'A',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'A',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'A',
                seatNo: 9,
                occupied: 'No',
                image: '/assets/seating/upper/A-9.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '10px'
            },
            {
                level: 'U',
                row: 'A',
                seatNo: 10,
                occupied: 'No',
                image: '/assets/seating/upper/A-10.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '10px'
            },
            {
                level: 'U',
                row: 'A',
                seatNo: 11,
                occupied: 'No',
                image: '/assets/seating/upper/A-11.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'A',
                seatNo: 12,
                occupied: 'No',
                image: '/assets/seating/upper/A-12.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '14px'
            },
            {
                level: 'U',
                row: 'A',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'A',
                seatNo: 13,
                occupied: 'No',
                image: '/assets/seating/upper/A-13.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '15px'
            },
            {
                level: 'U',
                row: 'A',
                seatNo: 14,
                occupied: 'No',
                image: '/assets/seating/upper/A-14.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'A',
                seatNo: 15,
                occupied: 'No',
                image: '/assets/seating/upper/A-15.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'A',
                seatNo: 16,
                occupied: 'No',
                image: '/assets/seating/upper/A-16.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'B',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '1px'
            },
            {
                level: 'U',
                row: 'B',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '1px'
            },
            {
                level: 'U',
                row: 'B',
                seatNo: 1,
                occupied: 'No',
                image: '/assets/seating/upper/B-1.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '34px'
            },
            {
                level: 'U',
                row: 'B',
                seatNo: 2,
                occupied: 'No',
                image: '/assets/seating/upper/B-2.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'B',
                seatNo: 3,
                occupied: 'No',
                image: '/assets/seating/upper/B-3.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'B',
                seatNo: 4,
                occupied: 'No',
                image: '/assets/seating/upper/B-4.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'B',
                seatNo: 5,
                occupied: 'No',
                image: '/assets/seating/upper/B-5.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'B',
                seatNo: 6,
                occupied: 'No',
                image: '/assets/seating/upper/B-6.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'B',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'B',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'B',
                seatNo: 7,
                occupied: 'No',
                image: '/assets/seating/upper/B-7.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '9px'
            },
            {
                level: 'U',
                row: 'B',
                seatNo: 8,
                occupied: 'No',
                image: '/assets/seating/upper/B-8.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'B',
                seatNo: 9,
                occupied: 'No',
                image: '/assets/seating/upper/B-9.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'B',
                seatNo: 10,
                occupied: 'No',
                image: '/assets/seating/upper/B-10.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '14px'
            },
            {
                level: 'U',
                row: 'B',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'B',
                seatNo: 11,
                occupied: 'No',
                image: '/assets/seating/upper/B-11.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'U',
                row: 'B',
                seatNo: 12,
                occupied: 'No',
                image: '/assets/seating/upper/B-12.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'B',
                seatNo: 13,
                occupied: 'No',
                image: '/assets/seating/upper/B-13.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'B',
                seatNo: 14,
                occupied: 'No',
                image: '/assets/seating/upper/B-14.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'C',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '1px'
            },
            {
                level: 'U',
                row: 'C',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '1px'
            },
            {
                level: 'U',
                row: 'C',
                seatNo: 1,
                occupied: 'No',
                image: '/assets/seating/upper/C-1.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '34px'
            },
            {
                level: 'U',
                row: 'C',
                seatNo: 2,
                occupied: 'No',
                image: '/assets/seating/upper/C-2.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'C',
                seatNo: 3,
                occupied: 'No',
                image: '/assets/seating/upper/C-3.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'C',
                seatNo: 4,
                occupied: 'No',
                image: '/assets/seating/upper/C-4.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'C',
                seatNo: 5,
                occupied: 'No',
                image: '/assets/seating/upper/C-5.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'C',
                seatNo: 6,
                occupied: 'No',
                image: '/assets/seating/upper/C-6.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'C',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'C',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'C',
                seatNo: 7,
                occupied: 'No',
                image: '/assets/seating/upper/C-7.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '9px'
            },
            {
                level: 'U',
                row: 'C',
                seatNo: 8,
                occupied: 'No',
                image: '/assets/seating/upper/C-8.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'C',
                seatNo: 9,
                occupied: 'No',
                image: '/assets/seating/upper/C-9.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'C',
                seatNo: 10,
                occupied: 'No',
                image: '/assets/seating/upper/C-10.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '14px'
            },
            {
                level: 'U',
                row: 'C',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'C',
                seatNo: 11,
                occupied: 'No',
                image: '/assets/seating/upper/C-11.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'C',
                seatNo: 12,
                occupied: 'No',
                image: '/assets/seating/upper/C-12.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'C',
                seatNo: 13,
                occupied: 'No',
                image: '/assets/seating/upper/C-13.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'C',
                seatNo: 14,
                occupied: 'No',
                image: '/assets/seating/upper/C-14.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'D',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '1px'
            },
            {
                level: 'U',
                row: 'D',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '1px'
            },
            {
                level: 'U',
                row: 'D',
                seatNo: 1,
                occupied: 'No',
                image: '/assets/seating/upper/D-1.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '34px'
            },
            {
                level: 'U',
                row: 'D',
                seatNo: 2,
                occupied: 'No',
                image: '/assets/seating/upper/D-2.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'D',
                seatNo: 3,
                occupied: 'No',
                image: '/assets/seating/upper/D-3.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'D',
                seatNo: 4,
                occupied: 'No',
                image: '/assets/seating/upper/D-4.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'D',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'D',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'D',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'D',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'D',
                seatNo: 5,
                occupied: 'No',
                image: '/assets/seating/upper/D-5.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '32px'
            },
            {
                level: 'U',
                row: 'D',
                seatNo: 6,
                occupied: 'No',
                image: '/assets/seating/upper/D-6.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'D',
                seatNo: 7,
                occupied: 'No',
                image: '/assets/seating/upper/D-7.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'D',
                seatNo: 8,
                occupied: 'No',
                image: '/assets/seating/upper/D-8.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'D',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'D',
                seatNo: 9,
                occupied: 'No',
                image: '/assets/seating/upper/D-9.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'D',
                seatNo: 10,
                occupied: 'No',
                image: '/assets/seating/upper/D-10.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'D',
                seatNo: 11,
                occupied: 'No',
                image: '/assets/seating/upper/D-11.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'D',
                seatNo: 12,
                occupied: 'No',
                image: '/assets/seating/upper/D-12.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'E',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '1px'
            },
            {
                level: 'U',
                row: 'E',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '1px'
            },
            {
                level: 'U',
                row: 'E',
                seatNo: 1,
                occupied: 'No',
                image: '/assets/seating/upper/E-1.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '34px'
            },
            {
                level: 'U',
                row: 'E',
                seatNo: 2,
                occupied: 'No',
                image: '/assets/seating/upper/E-2.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'E',
                seatNo: 3,
                occupied: 'No',
                image: '/assets/seating/upper/E-3.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'E',
                seatNo: 4,
                occupied: 'No',
                image: '/assets/seating/upper/E-4.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'E',
                seatNo: 5,
                occupied: 'No',
                image: '/assets/seating/upper/E-5.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'E',
                seatNo: 6,
                occupied: 'No',
                image: '/assets/seating/upper/E-6.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'E',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'E',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'E',
                seatNo: 7,
                occupied: 'No',
                image: '/assets/seating/upper/E-7.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '9px'
            },
            {
                level: 'U',
                row: 'E',
                seatNo: 8,
                occupied: 'No',
                image: '/assets/seating/upper/E-8.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'E',
                seatNo: 9,
                occupied: 'No',
                image: '/assets/seating/upper/E-9.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'E',
                seatNo: 10,
                occupied: 'No',
                image: '/assets/seating/upper/E-10.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '14px'
            },
            {
                level: 'U',
                row: 'E',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'E',
                seatNo: 11,
                occupied: 'No',
                image: '/assets/seating/upper/E-11.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'E',
                seatNo: 12,
                occupied: 'No',
                image: '/assets/seating/upper/E-12.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'E',
                seatNo: 13,
                occupied: 'No',
                image: '/assets/seating/upper/E-13.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'E',
                seatNo: 14,
                occupied: 'No',
                image: '/assets/seating/upper/E-14.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'F',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '1px'
            },
            {
                level: 'U',
                row: 'F',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '1px'
            },
            {
                level: 'U',
                row: 'F',
                seatNo: 1,
                occupied: 'No',
                image: '/assets/seating/upper/F-1.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '34px'
            },
            {
                level: 'U',
                row: 'F',
                seatNo: 2,
                occupied: 'No',
                image: '/assets/seating/upper/F-2.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'F',
                seatNo: 3,
                occupied: 'No',
                image: '/assets/seating/upper/F-3.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'F',
                seatNo: 4,
                occupied: 'No',
                image: '/assets/seating/upper/F-4.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'F',
                seatNo: 5,
                occupied: 'No',
                image: '/assets/seating/upper/F-5.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'F',
                seatNo: 6,
                occupied: 'No',
                image: '/assets/seating/upper/F-6.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'F',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'F',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'F',
                seatNo: 7,
                occupied: 'No',
                image: '/assets/seating/upper/F-7.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '9px'
            },
            {
                level: 'U',
                row: 'F',
                seatNo: 8,
                occupied: 'No',
                image: '/assets/seating/upper/F-8.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'F',
                seatNo: 9,
                occupied: 'No',
                image: '/assets/seating/upper/F-9.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'F',
                seatNo: 10,
                occupied: 'No',
                image: '/assets/seating/upper/F-10.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '14px'
            },
            {
                level: 'U',
                row: 'F',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'F',
                seatNo: 11,
                occupied: 'No',
                image: '/assets/seating/upper/F-11.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'F',
                seatNo: 12,
                occupied: 'No',
                image: '/assets/seating/upper/F-12.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'F',
                seatNo: 13,
                occupied: 'No',
                image: '/assets/seating/upper/F-13.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'F',
                seatNo: 14,
                occupied: 'No',
                image: '/assets/seating/upper/F-14.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'G',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '1px'
            },
            {
                level: 'U',
                row: 'G',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '1px'
            },
            {
                level: 'U',
                row: 'G',
                seatNo: 1,
                occupied: 'No',
                image: '/assets/seating/upper/G-1.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '34px'
            },
            {
                level: 'U',
                row: 'G',
                seatNo: 2,
                occupied: 'No',
                image: '/assets/seating/upper/G-2.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'G',
                seatNo: 3,
                occupied: 'No',
                image: '/assets/seating/upper/G-3.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'G',
                seatNo: 4,
                occupied: 'No',
                image: '/assets/seating/upper/G-4.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'G',
                seatNo: 5,
                occupied: 'No',
                image: '/assets/seating/upper/G-5.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'G',
                seatNo: 6,
                occupied: 'No',
                image: '/assets/seating/upper/G-6.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'G',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'G',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'G',
                seatNo: 7,
                occupied: 'No',
                image: '/assets/seating/upper/G-7.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '9px'
            },
            {
                level: 'U',
                row: 'G',
                seatNo: 8,
                occupied: 'No',
                image: '/assets/seating/upper/G-8.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'G',
                seatNo: 9,
                occupied: 'No',
                image: '/assets/seating/upper/G-9.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'G',
                seatNo: 10,
                occupied: 'No',
                image: '/assets/seating/upper/G-10.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '14px'
            },
            {
                level: 'U',
                row: 'G',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'G',
                seatNo: 11,
                occupied: 'No',
                image: '/assets/seating/upper/G-11.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'G',
                seatNo: 12,
                occupied: 'No',
                image: '/assets/seating/upper/G-12.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'G',
                seatNo: 13,
                occupied: 'No',
                image: '/assets/seating/upper/G-13.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'G',
                seatNo: 14,
                occupied: 'No',
                image: '/assets/seating/upper/G-14.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'H',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '1px'
            },
            {
                level: 'U',
                row: 'H',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '1px'
            },
            {
                level: 'U',
                row: 'H',
                seatNo: 1,
                occupied: 'No',
                image: '/assets/seating/upper/H-1.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '34px'
            },
            {
                level: 'U',
                row: 'H',
                seatNo: 2,
                occupied: 'No',
                image: '/assets/seating/upper/H-2.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'H',
                seatNo: 3,
                occupied: 'No',
                image: '/assets/seating/upper/H-3.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'H',
                seatNo: 4,
                occupied: 'No',
                image: '/assets/seating/upper/H-4.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'H',
                seatNo: 5,
                occupied: 'No',
                image: '/assets/seating/upper/H-5.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'H',
                seatNo: 6,
                occupied: 'No',
                image: '/assets/seating/upper/H-6.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'H',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'H',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'H',
                seatNo: 7,
                occupied: 'No',
                image: '/assets/seating/upper/H-7.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '9px'
            },
            {
                level: 'U',
                row: 'H',
                seatNo: 8,
                occupied: 'No',
                image: '/assets/seating/upper/H-8.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'H',
                seatNo: 9,
                occupied: 'No',
                image: '/assets/seating/upper/H-9.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'H',
                seatNo: 10,
                occupied: 'No',
                image: '/assets/seating/upper/H-10.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '14px'
            },
            {
                level: 'U',
                row: 'H',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'H',
                seatNo: 11,
                occupied: 'No',
                image: '/assets/seating/upper/H-11.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'H',
                seatNo: 12,
                occupied: 'No',
                image: '/assets/seating/upper/H-12.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'H',
                seatNo: 13,
                occupied: 'No',
                image: '/assets/seating/upper/H-13.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'H',
                seatNo: 14,
                occupied: 'No',
                image: '/assets/seating/upper/H-14.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'I',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '1px'
            },
            {
                level: 'U',
                row: 'I',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '1px'
            },
            {
                level: 'U',
                row: 'I',
                seatNo: 1,
                occupied: 'No',
                image: '/assets/seating/upper/I-1.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '34px'
            },
            {
                level: 'U',
                row: 'I',
                seatNo: 2,
                occupied: 'No',
                image: '/assets/seating/upper/I-2.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'I',
                seatNo: 3,
                occupied: 'No',
                image: '/assets/seating/upper/I-3.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'I',
                seatNo: 4,
                occupied: 'No',
                image: '/assets/seating/upper/I-4.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'I',
                seatNo: 5,
                occupied: 'No',
                image: '/assets/seating/upper/I-5.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'I',
                seatNo: 6,
                occupied: 'No',
                image: '/assets/seating/upper/I-7.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'I',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'I',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'I',
                seatNo: 7,
                occupied: 'No',
                image: '/assets/seating/upper/I-7.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '9px'
            },
            {
                level: 'U',
                row: 'I',
                seatNo: 8,
                occupied: 'No',
                image: '/assets/seating/upper/I-8.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'I',
                seatNo: 9,
                occupied: 'No',
                image: '/assets/seating/upper/I-9.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'I',
                seatNo: 10,
                occupied: 'No',
                image: '/assets/seating/upper/I-10.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '14px'
            },
            {
                level: 'U',
                row: 'I',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'I',
                seatNo: 11,
                occupied: 'No',
                image: '/assets/seating/upper/I-11.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'I',
                seatNo: 12,
                occupied: 'No',
                image: '/assets/seating/upper/I-12.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'I',
                seatNo: 13,
                occupied: 'No',
                image: '/assets/seating/upper/I-13.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'I',
                seatNo: 14,
                occupied: 'No',
                image: '/assets/seating/upper/I-14.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'J',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '1px'
            },
            {
                level: 'U',
                row: 'J',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '1px'
            },
            {
                level: 'U',
                row: 'J',
                seatNo: 1,
                occupied: 'No',
                image: '/assets/seating/upper/J-1.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '34px'
            },
            {
                level: 'U',
                row: 'J',
                seatNo: 2,
                occupied: 'No',
                image: '/assets/seating/upper/J-2.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'J',
                seatNo: 3,
                occupied: 'No',
                image: '/assets/seating/upper/J-3.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'J',
                seatNo: 4,
                occupied: 'No',
                image: '/assets/seating/upper/J-4.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'J',
                seatNo: 5,
                occupied: 'No',
                image: '/assets/seating/upper/J-5.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'J',
                seatNo: 6,
                occupied: 'No',
                image: '/assets/seating/upper/J-6.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'J',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'J',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'J',
                seatNo: 7,
                occupied: 'No',
                image: '/assets/seating/upper/J-7.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '9px'
            },
            {
                level: 'U',
                row: 'J',
                seatNo: 8,
                occupied: 'No',
                image: '/assets/seating/upper/J-8.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'U',
                row: 'J',
                seatNo: 9,
                occupied: 'No',
                image: '/assets/seating/upper/J-9.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'J',
                seatNo: 10,
                occupied: 'No',
                image: '/assets/seating/upper/J-10.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '14px'
            },
            {
                level: 'U',
                row: 'J',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'J',
                seatNo: 11,
                occupied: 'No',
                image: '/assets/seating/upper/J-11.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'J',
                seatNo: 12,
                occupied: 'No',
                image: '/assets/seating/upper/J-12.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'U',
                row: 'J',
                seatNo: 13,
                occupied: 'No',
                image: '/assets/seating/upper/J-13.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'U',
                row: 'J',
                seatNo: 14,
                occupied: 'No',
                image: '/assets/seating/upper/J-14.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },


        ]


    }

    emptyLowerLevel() {
        return [
            {
                level: 'L',
                row: 'A',
                seatNo: 1,
                occupied: 'No',
                image: '/assets/seating/lower/A-1.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '13px'
            },
            {
                level: 'L',
                row: 'A',
                seatNo: 2,
                occupied: 'No',
                image: '/assets/seating/lower/A-2.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'A',
                seatNo: 3,
                occupied: 'No',
                image: '/assets/seating/lower/A-3.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'A',
                seatNo: 4,
                occupied: 'No',
                image: '/assets/seating/lower/A-4.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'A',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'A',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'A',
                seatNo: 5,
                occupied: 'No',
                image: '/assets/seating/lower/A-5.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '8px'
            },
            {
                level: 'L',
                row: 'A',
                seatNo: 6,
                occupied: 'No',
                image: '/assets/seating/lower/A-6.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'A',
                seatNo: 7,
                occupied: 'No',
                image: '/assets/seating/lower/A-7.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'A',
                seatNo: 8,
                occupied: 'No',
                image: '/assets/seating/lower/A-8.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'A',
                seatNo: 9,
                occupied: 'No',
                image: '/assets/seating/lower/A-9.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'A',
                seatNo: 10,
                occupied: 'No',
                image: '/assets/seating/lower/A-10.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'A',
                seatNo: 11,
                occupied: 'No',
                image: '/assets/seating/lower/A-11.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'A',
                seatNo: 12,
                occupied: 'No',
                image: '/assets/seating/lower/A-12.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'A',
                seatNo: 13,
                occupied: 'No',
                image: '/assets/seating/lower/A-13.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'A',
                seatNo: 14,
                occupied: 'No',
                image: '/assets/seating/lower/A-14.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            
            {
                level: 'L',
                row: 'B',
                seatNo: 1,
                occupied: 'No',
                image: '/assets/seating/lower/B-1.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '74px'
            },
            {
                level: 'L',
                row: 'B',
                seatNo: 2,
                occupied: 'No',
                image: '/assets/seating/lower/B-2.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'B',
                seatNo: 3,
                occupied: 'No',
                image: '/assets/seating/lower/B-3.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'B',
                seatNo: 4,
                occupied: 'No',
                image: '/assets/seating/lower/B-4.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'B',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'B',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },
            
            {
                level: 'L',
                row: 'B',
                seatNo: 5,
                occupied: 'No',
                image: '/assets/seating/lower/B-5.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'B',
                seatNo: 6,
                occupied: 'No',
                image: '/assets/seating/lower/B-6.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'B',
                seatNo: 7,
                occupied: 'No',
                image: '/assets/seating/lower/B-7.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'B',
                seatNo: 8,
                occupied: 'No',
                image: '/assets/seating/lower/B-8.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'B',
                seatNo: 9,
                occupied: 'No',
                image: '/assets/seating/lower/B-9.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'B',
                seatNo: 10,
                occupied: 'No',
                image: '/assets/seating/lower/B-10.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'B',
                seatNo: 11,
                occupied: 'No',
                image: '/assets/seating/lower/B-11.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'B',
                seatNo: 12,
                occupied: 'No',
                image: '/assets/seating/lower/B-12.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'B',
                seatNo: 13,
                occupied: 'No',
                image: '/assets/seating/lower/B-13.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'B',
                seatNo: 14,
                occupied: 'No',
                image: '/assets/seating/lower/B-14.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },

            {
                level: 'L',
                row: 'C',
                seatNo: 1,
                occupied: 'No',
                image: '/assets/seating/lower/C-1.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '74px'
            },
            {
                level: 'L',
                row: 'C',
                seatNo: 2,
                occupied: 'No',
                image: '/assets/seating/lower/C-2.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'C',
                seatNo: 3,
                occupied: 'No',
                image: '/assets/seating/lower/C-3.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'C',
                seatNo: 4,
                occupied: 'No',
                image: '/assets/seating/lower/C-4.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'C',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'C',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },

            {
                level: 'L',
                row: 'C',
                seatNo: 5,
                occupied: 'No',
                image: '/assets/seating/lower/C-5.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'C',
                seatNo: 6,
                occupied: 'No',
                image: '/assets/seating/lower/C-6.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'C',
                seatNo: 7,
                occupied: 'No',
                image: '/assets/seating/lower/C-7.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'C',
                seatNo: 8,
                occupied: 'No',
                image: '/assets/seating/lower/C-8.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'C',
                seatNo: 9,
                occupied: 'No',
                image: '/assets/seating/lower/C-9.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'C',
                seatNo: 10,
                occupied: 'No',
                image: '/assets/seating/lower/C-10.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'C',
                seatNo: 11,
                occupied: 'No',
                image: '/assets/seating/lower/C-11.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'C',
                seatNo: 12,
                occupied: 'No',
                image: '/assets/seating/lower/C-12.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'C',
                seatNo: 13,
                occupied: 'No',
                image: '/assets/seating/lower/C-13.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'C',
                seatNo: 14,
                occupied: 'No',
                image: '/assets/seating/lower/C-14.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },

            {
                level: 'L',
                row: 'D',
                seatNo: 1,
                occupied: 'No',
                image: '/assets/seating/lower/D-1.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '74px'
            },
            {
                level: 'L',
                row: 'D',
                seatNo: 2,
                occupied: 'No',
                image: '/assets/seating/lower/D-2.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'D',
                seatNo: 3,
                occupied: 'No',
                image: '/assets/seating/lower/D-3.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'D',
                seatNo: 4,
                occupied: 'No',
                image: '/assets/seating/lower/D-4.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'D',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'D',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },

            {
                level: 'L',
                row: 'D',
                seatNo: 5,
                occupied: 'No',
                image: '/assets/seating/lower/D-5.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'D',
                seatNo: 6,
                occupied: 'No',
                image: '/assets/seating/lower/D-6.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'D',
                seatNo: 7,
                occupied: 'No',
                image: '/assets/seating/lower/D-7.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'D',
                seatNo: 8,
                occupied: 'No',
                image: '/assets/seating/lower/D-8.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'D',
                seatNo: 9,
                occupied: 'No',
                image: '/assets/seating/lower/D-9.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'D',
                seatNo: 10,
                occupied: 'No',
                image: '/assets/seating/lower/D-10.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'D',
                seatNo: 11,
                occupied: 'No',
                image: '/assets/seating/lower/D-11.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'D',
                seatNo: 12,
                occupied: 'No',
                image: '/assets/seating/lower/D-12.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'D',
                seatNo: 13,
                occupied: 'No',
                image: '/assets/seating/lower/D-13.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'D',
                seatNo: 14,
                occupied: 'No',
                image: '/assets/seating/lower/D-14.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },

            {
                level: 'L',
                row: 'E',
                seatNo: 1,
                occupied: 'No',
                image: '/assets/seating/lower/E-1.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '74px'
            },
            {
                level: 'L',
                row: 'E',
                seatNo: 2,
                occupied: 'No',
                image: '/assets/seating/lower/E-2.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'E',
                seatNo: 3,
                occupied: 'No',
                image: '/assets/seating/lower/E-3.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'E',
                seatNo: 4,
                occupied: 'No',
                image: '/assets/seating/lower/E-4.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'E',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'E',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },

            {
                level: 'L',
                row: 'E',
                seatNo: 5,
                occupied: 'No',
                image: '/assets/seating/lower/E-5.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'E',
                seatNo: 6,
                occupied: 'No',
                image: '/assets/seating/lower/E-6.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'E',
                seatNo: 7,
                occupied: 'No',
                image: '/assets/seating/lower/E-7.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'E',
                seatNo: 8,
                occupied: 'No',
                image: '/assets/seating/lower/E-8.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'E',
                seatNo: 9,
                occupied: 'No',
                image: '/assets/seating/lower/E-9.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'E',
                seatNo: 10,
                occupied: 'No',
                image: '/assets/seating/lower/E-10.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'E',
                seatNo: 11,
                occupied: 'No',
                image: '/assets/seating/lower/E-11.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'E',
                seatNo: 12,
                occupied: 'No',
                image: '/assets/seating/lower/E-12.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'E',
                seatNo: 13,
                occupied: 'No',
                image: '/assets/seating/lower/E-13.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'E',
                seatNo: 14,
                occupied: 'No',
                image: '/assets/seating/lower/E-14.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },


            {
                level: 'L',
                row: 'F',
                seatNo: 1,
                occupied: 'No',
                image: '/assets/seating/lower/F-1.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '74px'
            },
            {
                level: 'L',
                row: 'F',
                seatNo: 2,
                occupied: 'No',
                image: '/assets/seating/lower/F-2.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'F',
                seatNo: 3,
                occupied: 'No',
                image: '/assets/seating/lower/F-3.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'F',
                seatNo: 4,
                occupied: 'No',
                image: '/assets/seating/lower/F-4.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'F',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'F',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },

            {
                level: 'L',
                row: 'F',
                seatNo: 5,
                occupied: 'No',
                image: '/assets/seating/lower/F-5.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'F',
                seatNo: 6,
                occupied: 'No',
                image: '/assets/seating/lower/F-6.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'F',
                seatNo: 7,
                occupied: 'No',
                image: '/assets/seating/lower/F-7.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'F',
                seatNo: 8,
                occupied: 'No',
                image: '/assets/seating/lower/F-8.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'F',
                seatNo: 9,
                occupied: 'No',
                image: '/assets/seating/lower/F-9.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'F',
                seatNo: 10,
                occupied: 'No',
                image: '/assets/seating/lower/F-10.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'F',
                seatNo: 11,
                occupied: 'No',
                image: '/assets/seating/lower/F-11.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'F',
                seatNo: 12,
                occupied: 'No',
                image: '/assets/seating/lower/F-12.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'F',
                seatNo: 13,
                occupied: 'No',
                image: '/assets/seating/lower/F-13.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'F',
                seatNo: 14,
                occupied: 'No',
                image: '/assets/seating/lower/F-14.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },


            {
                level: 'L',
                row: 'G',
                seatNo: 1,
                occupied: 'No',
                image: '/assets/seating/lower/G-1.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '74px'
            },
            {
                level: 'L',
                row: 'G',
                seatNo: 2,
                occupied: 'No',
                image: '/assets/seating/lower/G-2.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'G',
                seatNo: 3,
                occupied: 'No',
                image: '/assets/seating/lower/G-3.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'G',
                seatNo: 4,
                occupied: 'No',
                image: '/assets/seating/lower/G-4.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'G',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'G',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },

            {
                level: 'L',
                row: 'G',
                seatNo: 5,
                occupied: 'No',
                image: '/assets/seating/lower/G-5.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'G',
                seatNo: 6,
                occupied: 'No',
                image: '/assets/seating/lower/G-6.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'G',
                seatNo: 7,
                occupied: 'No',
                image: '/assets/seating/lower/G-7.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'G',
                seatNo: 8,
                occupied: 'No',
                image: '/assets/seating/lower/G-8.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'G',
                seatNo: 9,
                occupied: 'No',
                image: '/assets/seating/lower/G-9.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'G',
                seatNo: 10,
                occupied: 'No',
                image: '/assets/seating/lower/G-10.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'G',
                seatNo: 11,
                occupied: 'No',
                image: '/assets/seating/lower/G-11.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'G',
                seatNo: 12,
                occupied: 'No',
                image: '/assets/seating/lower/G-12.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'G',
                seatNo: 13,
                occupied: 'No',
                image: '/assets/seating/lower/G-13.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'G',
                seatNo: 14,
                occupied: 'No',
                image: '/assets/seating/lower/G-14.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },

            {
                level: 'L',
                row: 'H',
                seatNo: 1,
                occupied: 'No',
                image: '/assets/seating/lower/H-1.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '74px'
            },
            {
                level: 'L',
                row: 'H',
                seatNo: 2,
                occupied: 'No',
                image: '/assets/seating/lower/H-2.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'H',
                seatNo: 3,
                occupied: 'No',
                image: '/assets/seating/lower/H-3.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'H',
                seatNo: 4,
                occupied: 'No',
                image: '/assets/seating/lower/H-4.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'H',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'H',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },

            {
                level: 'L',
                row: 'H',
                seatNo: 5,
                occupied: 'No',
                image: '/assets/seating/lower/H-5.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'H',
                seatNo: 6,
                occupied: 'No',
                image: '/assets/seating/lower/H-6.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'H',
                seatNo: 7,
                occupied: 'No',
                image: '/assets/seating/lower/H-7.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'H',
                seatNo: 8,
                occupied: 'No',
                image: '/assets/seating/lower/H-8.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'H',
                seatNo: 9,
                occupied: 'No',
                image: '/assets/seating/lower/H-9.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'H',
                seatNo: 10,
                occupied: 'No',
                image: '/assets/seating/lower/H-10.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'H',
                seatNo: 11,
                occupied: 'No',
                image: '/assets/seating/lower/H-11.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'H',
                seatNo: 12,
                occupied: 'No',
                image: '/assets/seating/lower/H-12.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'H',
                seatNo: 13,
                occupied: 'No',
                image: '/assets/seating/lower/H-13.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'H',
                seatNo: 14,
                occupied: 'No',
                image: '/assets/seating/lower/H-14.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },

            {
                level: 'L',
                row: 'I',
                seatNo: 1,
                occupied: 'No',
                image: '/assets/seating/lower/I-1.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '74px'
            },
            {
                level: 'L',
                row: 'I',
                seatNo: 2,
                occupied: 'No',
                image: '/assets/seating/lower/I-2.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'I',
                seatNo: 3,
                occupied: 'No',
                image: '/assets/seating/lower/I-3.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'I',
                seatNo: 4,
                occupied: 'No',
                image: '/assets/seating/lower/I-4.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'I',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'I',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },

            {
                level: 'L',
                row: 'I',
                seatNo: 5,
                occupied: 'No',
                image: '/assets/seating/lower/I-5.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'I',
                seatNo: 6,
                occupied: 'No',
                image: '/assets/seating/lower/I-6.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'I',
                seatNo: 7,
                occupied: 'No',
                image: '/assets/seating/lower/I-7.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'I',
                seatNo: 8,
                occupied: 'No',
                image: '/assets/seating/lower/I-8.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'I',
                seatNo: 9,
                occupied: 'No',
                image: '/assets/seating/lower/I-9.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'I',
                seatNo: 10,
                occupied: 'No',
                image: '/assets/seating/lower/I-10.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'I',
                seatNo: 11,
                occupied: 'No',
                image: '/assets/seating/lower/I-11.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'I',
                seatNo: 12,
                occupied: 'No',
                image: '/assets/seating/lower/I-12.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'I',
                seatNo: 13,
                occupied: 'No',
                image: '/assets/seating/lower/I-13.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'I',
                seatNo: 14,
                occupied: 'No',
                image: '/assets/seating/lower/I-14.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },

            {
                level: 'L',
                row: 'J',
                seatNo: 1,
                occupied: 'No',
                image: '/assets/seating/lower/J-1.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '74px'
            },
            {
                level: 'L',
                row: 'J',
                seatNo: 2,
                occupied: 'No',
                image: '/assets/seating/lower/J-2.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'J',
                seatNo: 3,
                occupied: 'No',
                image: '/assets/seating/lower/J-3.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'J',
                seatNo: 4,
                occupied: 'No',
                image: '/assets/seating/lower/J-4.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'J',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'J',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },

            {
                level: 'L',
                row: 'J',
                seatNo: 5,
                occupied: 'No',
                image: '/assets/seating/lower/J-5.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'J',
                seatNo: 6,
                occupied: 'No',
                image: '/assets/seating/lower/J-6.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'J',
                seatNo: 7,
                occupied: 'No',
                image: '/assets/seating/lower/J-7.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'J',
                seatNo: 8,
                occupied: 'No',
                image: '/assets/seating/lower/J-8.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'J',
                seatNo: 9,
                occupied: 'No',
                image: '/assets/seating/lower/J-9.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'J',
                seatNo: 10,
                occupied: 'No',
                image: '/assets/seating/lower/J-10.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'J',
                seatNo: 11,
                occupied: 'No',
                image: '/assets/seating/lower/J-11.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'J',
                seatNo: 12,
                occupied: 'No',
                image: '/assets/seating/lower/J-12.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'J',
                seatNo: 13,
                occupied: 'No',
                image: '/assets/seating/lower/J-13.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'J',
                seatNo: 14,
                occupied: 'No',
                image: '/assets/seating/lower/J-14.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },

            {
                level: 'L',
                row: 'K',
                seatNo: 1,
                occupied: 'No',
                image: '/assets/seating/lower/K-1.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '74px'
            },
            {
                level: 'L',
                row: 'K',
                seatNo: 2,
                occupied: 'No',
                image: '/assets/seating/lower/K-2.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'K',
                seatNo: 3,
                occupied: 'No',
                image: '/assets/seating/lower/K-3.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'K',
                seatNo: 4,
                occupied: 'No',
                image: '/assets/seating/lower/K-4.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'K',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'K',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },

            {
                level: 'L',
                row: 'K',
                seatNo: 5,
                occupied: 'No',
                image: '/assets/seating/lower/K-5.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'K',
                seatNo: 6,
                occupied: 'No',
                image: '/assets/seating/lower/K-6.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'K',
                seatNo: 7,
                occupied: 'No',
                image: '/assets/seating/lower/K-7.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'K',
                seatNo: 8,
                occupied: 'No',
                image: '/assets/seating/lower/K-8.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'K',
                seatNo: 9,
                occupied: 'No',
                image: '/assets/seating/lower/K-9.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'K',
                seatNo: 10,
                occupied: 'No',
                image: '/assets/seating/lower/K-10.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'K',
                seatNo: 11,
                occupied: 'No',
                image: '/assets/seating/lower/K-11.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'K',
                seatNo: 12,
                occupied: 'No',
                image: '/assets/seating/lower/K-12.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'K',
                seatNo: 13,
                occupied: 'No',
                image: '/assets/seating/lower/K-13.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'K',
                seatNo: 14,
                occupied: 'No',
                image: '/assets/seating/lower/K-14.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },

            {
                level: 'L',
                row: 'L',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'L',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            }, {
                level: 'L',
                row: 'L',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'L',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'L',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'L',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },

            {
                level: 'L',
                row: 'L',
                seatNo: 5,
                occupied: 'No',
                image: '/assets/seating/lower/L-5.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'L',
                seatNo: 6,
                occupied: 'No',
                image: '/assets/seating/lower/L-6.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'L',
                seatNo: 7,
                occupied: 'No',
                image: '/assets/seating/lower/L-7.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'L',
                seatNo: 8,
                occupied: 'No',
                image: '/assets/seating/lower/L-8.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'L',
                seatNo: 9,
                occupied: 'No',
                image: '/assets/seating/lower/L-9.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'L',
                seatNo: 10,
                occupied: 'No',
                image: '/assets/seating/lower/L-10.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'L',
                seatNo: 11,
                occupied: 'No',
                image: '/assets/seating/lower/L-11.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'L',
                seatNo: 12,
                occupied: 'No',
                image: '/assets/seating/lower/L-12.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'L',
                seatNo: 13,
                occupied: 'No',
                image: '/assets/seating/lower/L-13.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'L',
                seatNo: 14,
                occupied: 'No',
                image: '/assets/seating/lower/L-14.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },

            {
                level: 'L',
                row: 'M',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'M',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            }, {
                level: 'L',
                row: 'M',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'M',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'M',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'M',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },

            {
                level: 'L',
                row: 'M',
                seatNo: 5,
                occupied: 'No',
                image: '/assets/seating/lower/M-5.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'M',
                seatNo: 6,
                occupied: 'No',
                image: '/assets/seating/lower/M-6.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'M',
                seatNo: 7,
                occupied: 'No',
                image: '/assets/seating/lower/M-7.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'M',
                seatNo: 8,
                occupied: 'No',
                image: '/assets/seating/lower/M-8.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'M',
                seatNo: 9,
                occupied: 'No',
                image: '/assets/seating/lower/M-9.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'M',
                seatNo: 10,
                occupied: 'No',
                image: '/assets/seating/lower/M-10.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'M',
                seatNo: 11,
                occupied: 'No',
                image: '/assets/seating/lower/M-11.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'M',
                seatNo: 12,
                occupied: 'No',
                image: '/assets/seating/lower/M-12.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'M',
                seatNo: 13,
                occupied: 'No',
                image: '/assets/seating/lower/M-13.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'M',
                seatNo: 14,
                occupied: 'No',
                image: '/assets/seating/lower/M-14.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },

            {
                level: 'L',
                row: 'N',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'N',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'N',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'N',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'N',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankLeft.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'N',
                seatNo: 0,
                occupied: 'No',
                image: '/assets/seating/BlankRight.png',
                playeremail: '',
                playername: '',
                hide: true,
                padding: '6px'
            },

            {
                level: 'L',
                row: 'N',
                seatNo: 5,
                occupied: 'No',
                image: '/assets/seating/lower/N-5.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'N',
                seatNo: 6,
                occupied: 'No',
                image: '/assets/seating/lower/N-6.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'N',
                seatNo: 7,
                occupied: 'No',
                image: '/assets/seating/lower/N-7.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'N',
                seatNo: 8,
                occupied: 'No',
                image: '/assets/seating/lower/N-8.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '6px'
            },
            {
                level: 'L',
                row: 'N',
                seatNo: 9,
                occupied: 'No',
                image: '/assets/seating/lower/N-9.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'N',
                seatNo: 10,
                occupied: 'No',
                image: '/assets/seating/lower/N-10.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'N',
                seatNo: 11,
                occupied: 'No',
                image: '/assets/seating/lower/N-11.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '18px'
            },
            {
                level: 'L',
                row: 'N',
                seatNo: 12,
                occupied: 'No',
                image: '/assets/seating/lower/N-12.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },
            {
                level: 'L',
                row: 'N',
                seatNo: 13,
                occupied: 'No',
                image: '/assets/seating/lower/N-13.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '22px'
            },
            {
                level: 'L',
                row: 'N',
                seatNo: 14,
                occupied: 'No',
                image: '/assets/seating/lower/N-14.png',
                playeremail: '',
                playername: '',
                hide: false,
                padding: '12px'
            },



        ]
    }

    fetchselectedseats(id: number) {
        console.log(id);
        this.http.get<Array<string>>(`/api/events/${id}/seats`).subscribe(x => {
            this.selectedeventseats.next(x);
        },
            error => {
                console.log('Error extracting future events')

            });
    }


    fetchtempseats(id: number) {
        console.log(id);
        this.http.get<Array<TempSeat>>(`/api/events/${id}/seating`).subscribe(x => {
            console.log(x);
            let list: Array<string> = [];
            x.forEach(s => {
                list.push(s.seat);
            });

            this.selectedtempseats.next(list);
        },
            error => {
                console.log('Error extracting temp seats')
            });
    }

    savetempseat(eventid: number, s: string) {
        let tmp = {
            id: 0,
            eventid: eventid,
            seat: s,
            created: new Date
        }
        console.log(tmp);

        let promise = new Promise((resolve, reject) => {
            this.http.post(`api/events/${eventid}/saveseat`, tmp).subscribe(x => {
                console.log(x);
                console.log('Succesfully Save Temp Seat');

                resolve(x);
            },
                error => {
                    console.log('Error Saving Temp Seat');
                    reject('');
                });
        });
        return promise;

    }

    cleartempseat(eventid: number, s: string) {
        let tmp = {
            id: 0,
            eventid: eventid,
            seat: s,
            created: new Date
        }
        console.log(tmp);

        let promise = new Promise((resolve, reject) => {
            this.http.post(`api/events/${eventid}/clearseat`, tmp).subscribe(x => {
                console.log(x);
                console.log('Succesfully Clear Temp Seat');

                resolve(x);
            },
                error => {
                    console.log('Error Clear Temp Seat');
                    reject('');
                });
        });
        return promise;

    }


}