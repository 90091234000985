import { Component, OnInit, ViewChild } from '@angular/core';
import { CalendarComponent } from 'ng-fullcalendar';
import { Options } from 'fullcalendar';

import { EventService } from 'src/app/service/event.service';

//import { Moment } from 'moment'


@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
})

export class CalendarPageComponent implements OnInit {

  loading = false;
  events = [];
  calendarOptions: Options;
  displayEvent: any;
  @ViewChild(CalendarComponent) ucCalendar: CalendarComponent;

  constructor(protected eventService: EventService) {
    this.loadEvents();
   }

  
    ngOnInit() { 

      let moment = require('moment');
      
      this.eventService.calendarEvents.subscribe(evts => {
        this.events = evts;
        this.calendarOptions = {
          themeSystem: 'bootstrap3',
          defaultView: 'month',
          editable: false,
          eventLimit: false,
          duration: moment.duration({hour:3}),
          header: {
            left: 'prev,next today',
            center: 'title',
            right: 'month,agendaWeek,agendaDay,listMonth'
          },
          selectable: true,
          events: this.events,
          };

          setTimeout(function() {
          this.loading = false
      
          }.bind(this),
          500);

          
      });

    }

    
    clearEvents() {
      this.events = [];
    }

    loadEvents() {
      this.loading = true;
      this.eventService.calendarEvents.subscribe(data => {
        this.events = data;
      });
    }
    

  clickButton(model: any) {
    this.displayEvent = model;
  }
  
  eventClick(model: any) {
    model = {
      event: {
        id: model.event.id,
        start: model.event.start,
        end: model.event.end,
        title: model.event.title,
        allDay: model.event.allDay
        // other params
      },
      duration: {}
    }
    this.displayEvent = model;
  }
  
  updateEvent(model: any) {
    model = {
      event: {
        id: model.event.id,
        start: model.event.start,
        end: model.event.end,
        title: model.event.title
        // other params
      },
      duration: {
        _data: model.duration._data
      }
    }
    this.displayEvent = model;
  }
}


    